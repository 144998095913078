import React, { useState, useEffect } from "react";
import Select from 'react-select';
import _ from "lodash"; 

import callApi from "../callApi";
import { data } from "./data.js";
import "./style.css";

const Providers = () => {
    const [originalProviders, setOriginalProviders] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [responseMessage, setResponseMessage] = useState(
        "There are no records to display"
    );
    const [pending, setPending] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        getProvidersData();
    }, [])

    const getProvidersData = () => {
        setPending(true);
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/providers`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.providers) {
                    let allProviders = []
                    for (let provider in json.data.providers) {
                        if (json.data.providers[provider].visible) {
                            allProviders.push({
                                id: provider,
                                aggregator_ios: json.data.providers[provider].aggregator_ios,
                                aggregator_android: json.data.providers[provider].aggregator_android
                            })
                        }
                    }
                    setOriginalProviders(allProviders);
                    setAllProviders(allProviders);
                    setSelectedProviders([]);
                }
                if (json.status && json.status.message) {
                    setResponseMessage(json.status.message);
                }
                setPending(false);
            })
            .catch((err) => err);
    };

    const updateProviderData = () => {
        setIsSaving(true);
        let toUpdateProviders = [];
        for (let i=0; i < selectedProviders.length; i++) {
            if (selectedProviders[i] === undefined) {
                continue;
            }
            let toUpdateProvider = {
                id: selectedProviders[i].id
            };
            if (selectedProviders[i].aggregator_ios !== originalProviders[i].aggregator_ios) {
                    toUpdateProvider.aggregator_ios = selectedProviders[i].aggregator_ios;
            }
            if (selectedProviders[i].aggregator_android !== originalProviders[i].aggregator_android) {
                    toUpdateProvider.aggregator_android = selectedProviders[i].aggregator_android;
            }
            if (toUpdateProvider.aggregator_ios !== undefined || 
                toUpdateProvider.aggregator_android !== undefined) {
                toUpdateProviders.push(toUpdateProvider);
            }
        }
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/providers`;
        callApi(url, 'PUT', {'providers': toUpdateProviders})
            .then((json) => {
                setAllProviders([]);
                setSelectedProviders([]);
                setOriginalProviders([]);
                getProvidersData();
                setIsSaving(false);
            })
            .catch((err) => err);
    }

    const aggregator_values = [
        { value: 'saltedge', label: 'Saltedge' },
        { value: 'finverse', label: 'Finverse' },
        { value: 'brankas', label: 'Brankas' },
        { value: '', label: 'Disabled' }
    ];

    const getAggregatorDefaultValue = (aggregator) => {
        let aggregator_default_values = {
            saltedge: aggregator_values[0],
            finverse: aggregator_values[1],
            brankas: aggregator_values[2],
            disabled: aggregator_values[3]
        };
        return aggregator ? 
            aggregator_default_values[aggregator] : 
            aggregator_default_values.disabled
    };

    const setProviderAggregator = (index, field, selected) => {
        if (allProviders[index][field] !== selected.value) {
            let providers = _.cloneDeep(allProviders);
            let toUpdateProviders = _.cloneDeep(selectedProviders);
            let provider = _.cloneDeep(providers[index]);

            provider[field] = selected.value ? selected.value : null;

            providers[index] = provider;
            toUpdateProviders[index] = provider;
            setAllProviders(providers);
            setSelectedProviders(toUpdateProviders);
        }
    };

    return (
        <div className="provider-page">
            <h1 className="title">
                Providers
            </h1>
            {
                pending ? 
                    <div className="loading">
                        Loading...
                    </div> : allProviders.length > 0 ?
                        <div>
                            <table className="table provider-container">
                                <thead className="text-center align-top">
                                    <tr>
                                        <th className="border border-dark dobin-primary">
                                            Providers
                                        </th>
                                        <th className="border border-dark dobin-primary">
                                            Aggregator IOS
                                        </th>
                                        <th className="border border-dark dobin-primary">
                                            Aggregator Android
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-center align-top">
                                    {
                                        allProviders.map((provider, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="border border-dark col-md-3">
                                                        {provider.id}
                                                    </td>
                                                    <td 
                                                        className={
                                                            originalProviders[index].aggregator_ios !== provider.aggregator_ios ?
                                                                "border border-dark col-md-3 dobin-primary":
                                                                "border border-dark col-md-3"
                                                        }>
                                                        <Select
                                                            name="aggregator_ios"
                                                            options={data[provider.id] ? data[provider.id] : aggregator_values}
                                                            defaultValue={getAggregatorDefaultValue(provider.aggregator_ios)}
                                                            onChange={(selected) => setProviderAggregator(index, 'aggregator_ios', selected)} />
                                                    </td>
                                                    <td 
                                                        className={
                                                            originalProviders[index].aggregator_android !== provider.aggregator_android ?
                                                                "border border-dark col-md-3 dobin-primary":
                                                                "border border-dark col-md-3"
                                                        }>
                                                        <Select
                                                            name="aggregator_android"
                                                            options={data[provider.id] ? data[provider.id] : aggregator_values}
                                                            defaultValue={getAggregatorDefaultValue(provider.aggregator_android)}
                                                            onChange={(selected) => setProviderAggregator(index, 'aggregator_android', selected)} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="provider-container">
                                <button 
                                    className="provider-button"
                                    onClick={updateProviderData}
                                    disabled={isSaving}>
                                        Save
                                </button>
                            </div>
                        </div> : 
                        <div className="loading">
                            {responseMessage}
                        </div>
            }
        </div>
    );
};

export default Providers