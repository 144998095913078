export const data = {
    source: "dobin",
    aggregator: "",
    source_id: "",
    country_code: "SG",
    voucher_type: "",
    categories: [""],
    title: "",
    description: "",
    other_restrictions: [""],
    other_benefits: [""],
    merchant_name: "",
    merchant_phone_number: "",
    merchant_logo_url: "",
    website_url: "",
    redeem_url: "",
    coupon_url: "",
    coupon_image_url: "",
    expiry_date: "",
    validity_start_date: "",
    currency: "SGD",
    applicable_users: "",
    eligible_banks: [""],
    eligible_cards: [""],
    amount: "",
    dobin_amount: "",
    percentage: "",
    dobin_percentage: "",
    additional_amount: "",
    additional_percentage: "",
    min_amount: "",
    min_percentage: "",
    max_amount: "",
    max_percentage: "",
    amount_multiplier: "",
    percentage_multiplier: "",
    min_spend: "",
    min_spend_text: "",
    max_spend: "",
    multi_buy: "",
    multi_get: "",
    max_redeem_count: "",
    has_code: false,
    promo_code: "",
    commission_id: "",
    featured_position: "",
    app_featured: false,
    display_order: "",
    flagged: false
};