const allAggregatorValues = [
    { value: 'saltedge', label: 'Saltedge' },
    { value: 'finverse', label: 'Finverse' },
    { value: '', label: 'Disabled' }
];

const saltedgeAggregatorValues = [
    { value: 'saltedge', label: 'Saltedge' },
    { value: '', label: 'Disabled' }
];

const finverseAggregatorValues = [
    { value: 'finverse', label: 'Finverse' },
    { value: '', label: 'Disabled' }
];

const brankasAggregatorValues = [
    { value: 'brankas', label: 'Brankas' },
    { value: '', label: 'Disabled' }
];

export const data = {
    american_express_sg: saltedgeAggregatorValues,
    bank_of_china_sg: saltedgeAggregatorValues,
    bdo_ph: brankasAggregatorValues,
    bpi_ph: finverseAggregatorValues,
    cimb_bank_sg: saltedgeAggregatorValues,
    citi_sg: finverseAggregatorValues,
    dbs_sg: allAggregatorValues,
    fakebank_interactive_xf: saltedgeAggregatorValues,
    fakebank_semi_interactive_xf: saltedgeAggregatorValues,
    fakebank_two_step_interactive_xf: saltedgeAggregatorValues,
    fakebank_with_crypto_assets_xf: saltedgeAggregatorValues,
    fakebank_with_natures_xf: saltedgeAggregatorValues,
    finverse_test_bank: allAggregatorValues,
    hsbc_sg: saltedgeAggregatorValues,
    landbank_ph: finverseAggregatorValues,
    maya_ph: finverseAggregatorValues,
    metrobank_web_ph: finverseAggregatorValues,
    ocbc_sg: allAggregatorValues,
    ofbank_ph: finverseAggregatorValues,
    rcbc_ph: finverseAggregatorValues,
    scb_sg: saltedgeAggregatorValues,
    security_bank_ph: finverseAggregatorValues,
    unionbank_ph_ph: finverseAggregatorValues,
    uob_sg: allAggregatorValues
};