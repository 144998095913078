import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import Switch from "react-switch";
import { isBase64 } from 'is-base64';
import _ from "lodash";

import callApi from "../callApi";

const MerchantOfferAction = ({
    merchantOffer,
    handleModalClose,
    setButtonDisabled,
    updateMerchantOfferTable
}) => {
    const get_ddmmyyyy_date = (date_string) => {
        if (!date_string) {
            return "";
        }
        let date = new Date(date_string);

        let utc_date = date.getUTCDate();
        if (utc_date < 10) {
            utc_date = "0" + utc_date; 
        }

        let utc_month =  date.getUTCMonth();
        utc_month = utc_month + 1;
        if (utc_month < 10) {
            utc_month = "0" + utc_month;
        }

        let utc_year = date.getFullYear();

        return utc_year + "-" + utc_month + "-" + utc_date;
    };

    let data = _.cloneDeep(merchantOffer);
    data.validity_start_date = get_ddmmyyyy_date(data.validity_start_date);
    data.expiry_date = get_ddmmyyyy_date(data.expiry_date);

    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [sourceBasedCountry, setSourceBasedCountry] = useState([]);

    const [commission, setCommission] = useState({});

    const getCommission = (commission_id) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_offers_commission`;
        url = url + `?commission_id=${commission_id}`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.merchant_offers_commission) {
                    data['commission'] = json.data.merchant_offers_commission;
                    setCommission(json.data.merchant_offers_commission);
                }
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (Object.keys(commission).length === 0 && data.commission_id) {
            getCommission(data.commission_id);
        }
    }, [commission]);

    const aggregators = [
        { value: 'optimise_media', label: 'optimise_media' },
        { value: 'impact', label: 'impact' },
        { value: 'involve_asia', label: 'involve_asia' },
        { value: 'rakuten', label: 'rakuten' },
        { value: 'shopee', label: 'shopee' },
    ];

    const voucher_types = [
        { value: 'multi_buy', label: 'Multi Buy' },
        { value: 'discount', label: 'Discount' },
        { value: 'cashback', label: 'Cashback' },
        { value: 'free_delivery', label: 'Free Delivery' },
        { value: 'free_shipping', label: 'Free Shipping' },
        { value: 'gift', label: 'Gift' }
    ];

    const merchant_offer_number_fields = [
        "amount", "dobin_amount", "percentage", "dobin_percentage", 
        "additional_amount", "additional_percentage",
        "min_amount", "min_percentage", "max_amount", "max_percentage",
        "amount_multiplier", "percentage_multiplier", "min_spend", "max_spend",
        "multi_buy", "multi_get", "max_redeem_count", "featured_position",
    ];

    const getCategories = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/categories`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.categories) {
                    let category_ids = Object.keys(json.data.categories);
                    let categories = [];
                    for (let category_id of category_ids) {
                        categories.push({
                            value: category_id,
                            label: category_id
                        });
                    }
                    setCategories(categories);
                }
            })
            .catch((err) => err);
    };

    const getCountries = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/core/country_codes`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.country_codes) {
                    let countries = [];
                    for (let country_code of json.data.country_codes) {
                        countries.push({
                            value: country_code,
                            label: country_code
                        });
                    }
                    setCountries(countries);
                }
            })
            .catch((err) => err);
    };

    const getDiscountSources = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/discount_sources`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.discount_sources) {
                    let sourceBasedCountry = {}
                    for (let source in json.data.discount_sources) {
                        sourceBasedCountry[source] = json.data.discount_sources[source].country_code
                    }
                    setSourceBasedCountry(sourceBasedCountry);
                }
            })
            .catch((err) => err);
    };

    const dataTransformations = (merchant_offer) => {
        let result = {};
        if ('flagged' in data && data['flagged'] === false) {
            delete data['flagged']
        }
        if ('commission' in merchant_offer) {
            delete merchant_offer['commission'];
        }
        for (let key in merchant_offer) {
            if (merchant_offer_number_fields.includes(key) && merchant_offer[key] !== "") {
                result[key] = parseFloat(merchant_offer[key]);
            }
            else if(typeof(merchant_offer[key]) == "string" && merchant_offer[key] !== "") {
                result[key] = merchant_offer[key];
            }
            else if(_.isArray(merchant_offer[key]) && merchant_offer[key].length > 0) {
                let items = [];
                for (let value of merchant_offer[key]) {
                    if (value !== "") {
                        items.push(value);
                    }
                }
                if(items.length > 0) {
                    result[key] = items;
                }
            }
            // else if(typeof(merchant_offer[key]) == "object") {
            //     let object_result = dataTransformations(merchant_offer[key]);
            //     if (!_.isEmpty(object_result)) {
            //         result[key] = object_result;
            //     }
            // }
            else if(typeof(merchant_offer[key]) == "boolean") {
                result[key] = merchant_offer[key];
            }
        }
        return result;
    };

    const updateMerchantOffer = (merchant_offer, setSubmitting, setButtonDisabled) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_offers`;
        callApi(url, 'PUT', {'merchant_offers': [merchant_offer]})
            .then((json) => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleModalClose();
                updateMerchantOfferTable();
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getCategories();
        }
    }, [categories]);

    useEffect(() => {
        if (countries.length === 0) {
            getCountries();
        }
    }, [countries]);

    useEffect(() => {
        if (Object.keys(sourceBasedCountry).length === 0) {
            getDiscountSources();
        }
    }, [sourceBasedCountry]);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    
    const handleIcon = async (e, setFieldValue, fieldName) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setFieldValue(fieldName, base64);
    };

    return (
        <div className="merchant-offer-page">
            <Formik
                initialValues={data}
                validate={values => {
                    const phoneNumberRegex = /(\+65)?(8|9)\d{7}/;
                    const isNumberRegex = /^-?\d*\.?\d*$/;
                    const errors = {};
                    if (!values.country_code) {
                        errors.country_code = 'Required';
                    }
                    if (values.country_code && 
                        sourceBasedCountry[values.source] &&
                        values.country_code !== sourceBasedCountry[values.source]) {
                            errors.country_code = "Invalid Country Code"
                    }
                    if (!values.voucher_type) {
                        errors.voucher_type = 'Required';
                    }
                    if (values.categories.length > 0 && !values.categories[0]) {
                        errors.categories = 'Required';
                    }
                    if (!values.title) {
                        errors.title = 'Required';
                    }
                    if (!values.merchant_name) {
                        errors.merchant_name = 'Required';
                    };
                    if (!values.coupon_url) {
                        errors.coupon_url = 'Required';
                    }
                    if (values.has_code && !values.promo_code) {
                        errors.promo_code = 'Required';
                    }
                    if (values.merchant_phone_number && !(phoneNumberRegex.test(values.merchant_phone_number))) {
                        errors.merchant_phone_number = 'Enter valid Singapore phone number'
                    }
                    if (values.coupon_image_url && 
                        !values.coupon_image_url.startsWith('https://') &&
                        !(isBase64(values.coupon_image_url, { allowMime: true }))) {
                        errors.coupon_image_url = 'Invalid URL'
                    }
                    if (values.merchant_logo_url && 
                        !values.merchant_logo_url.startsWith('https://') &&
                        !(isBase64(values.merchant_logo_url, { allowMime: true }))) {
                        errors.merchant_logo_url = 'Invalid URL'
                    }
                    for (let value of ['website_url', 'redeem_url', 'coupon_url']) {
                        if (values[value] && !values[value].startsWith('https://')) {
                            errors[value] = 'Invalid URL'
                        }
                    }
                    for (let value of merchant_offer_number_fields) {
                        if (values[value] && !(isNumberRegex.test(values[value]))) {
                            errors[value] = "Invalid Number";
                        }
                    }
                    if (values.dobin_amount && values.dobin_percentage) {
                        errors.dobin_amount = "We can't have both dobin amount and dobin percentage"
                        errors.dobin_percentage = errors.dobin_amount
                    }
                    if (values.dobin_amount) {
                        if (values.amount === '' || values.amount === undefined || values.amount === null) {
                            errors.amount = 'There is a Dobin Amount. Thus, Amount cannot be empty! Enter 0 or other numeric value'
                        }
                        if (values.percentage) {
                            errors.percentage = 'There is a Dobin Amount. Thus, Percentage should be empty!'
                        }
                    }
                    if (values.dobin_percentage) {
                        if (values.percentage === '' || values.percentage === undefined || values.percentage === null) {
                            errors.percentage = 'There is a Dobin Percentage. Thus, Percentage cannot be empty! Enter 0 or other numeric value'
                        }
                        if (values.amount) {
                            errors.amount = 'There is a Dobin Percentage. Thus, Amount should be empty!'
                        }
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setButtonDisabled(true);
                    let merchant_offer = dataTransformations(values);
                    updateMerchantOffer(merchant_offer, setSubmitting, setButtonDisabled);
                }}
                >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form id="merchant-offer-edit">
                        <div className="row">
                            <label className="col-md-3">ID:*</label>
                            <Field 
                                className="col-md-8" 
                                readOnly type="text" name="id" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Aggregator:</label>
                            <Select
                                className="col-md-8"
                                name="aggregator"
                                options={aggregators}
                                defaultValue={
                                    { 
                                        value: data.aggregator, 
                                        label: data.aggregator
                                    }
                                }
                                onChange={(selected) => setFieldValue("aggregator", selected.value)} />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Source:*</label>
                            <Field 
                                className="col-md-8" 
                                readOnly type="text" name="source" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Source ID:*</label>
                            <Field 
                                className="col-md-8" 
                                readOnly type="text" name="source_id" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Country Code:*</label>
                            <Select
                                className="col-md-8"
                                name="country_code"
                                options={countries}
                                defaultValue={
                                    { 
                                        value: data.country_code, 
                                        label: data.country_code
                                    }
                                }
                                onChange={(selected) => setFieldValue("country_code", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="country_code" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Voucher Type:*</label>
                            <Select
                                // isDisabled={data.source === 'dobin' ? false : true}
                                className="col-md-8"
                                name="voucher_type"
                                options={voucher_types}
                                defaultValue={
                                    { 
                                        value: data.voucher_type, 
                                        label: (function() {
                                            for (let voucher_type of voucher_types) {
                                                if (voucher_type.value === data.voucher_type) {
                                                    return voucher_type.label
                                                } 
                                            }
                                            return data.voucher_type
                                        }())
                                    }
                                }
                                onChange={(selected) => setFieldValue("voucher_type", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="voucher_type" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Categories:*</label>
                            <Select
                                // isDisabled={data.source === 'dobin' ? false : true}
                                isMulti
                                className="col-md-8"
                                name="categories"
                                options={categories}
                                defaultValue={
                                    data.categories.map(category => {
                                        return {
                                            label: category,
                                            value: category
                                        };
                                    })
                                }
                                onChange={(selected) => {
                                    let categories = [];
                                    for (let item of selected) {
                                        categories.push(item.value);
                                    }
                                    setFieldValue("categories", categories);
                                }} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="categories" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Title:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="title"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="title" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Merchant Name:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="merchant_name"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="merchant_name" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Coupon URL:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="coupon_url"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="coupon_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Has Code:*</label>
                            <Switch
                                // disabled={data.source === 'dobin' ? false : true}
                                className="col-md-8"
                                checked={values.has_code}
                                onChange={() => {
                                    setFieldValue("has_code", !values.has_code)
                                    if (!values.has_code === true) {
                                        setFieldValue("promo_code", "")
                                    }
                                }} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="has_code" component="div" />
                        </div>
                        {
                            values.has_code ? <div className="row mt-40">
                                <label className="col-md-3">Promo Code:*</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="promo_code"
                                    // readOnly={data.source === 'dobin' ? false : true} 
                                />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="promo_code" component="div" />
                            </div> : null
                        }
                        <div className="row mt-40">
                            <label className="col-md-3">Description:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="description"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Restrictions:</label>
                            <FieldArray
                                className="col-md-8"
                                name="other_restrictions"
                                render={arrayHelpers => (
                                <div>
                                    {values.other_restrictions && values.other_restrictions.length > 0 ? (
                                    values.other_restrictions.map((other_restriction, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`other_restrictions.${index}`}
                                            // readOnly={data.source === 'dobin' ? false : true} 
                                        />
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button> : null
                                        }
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button> : null
                                        }
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}
                                        // disabled={ data.source === 'dobin' ? false : true }
                                    >
                                        Add Restrictions
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Benefits:</label>
                            <FieldArray
                                className="col-md-8"
                                name="other_benefits"
                                render={arrayHelpers => (
                                <div>
                                    {values.other_benefits && values.other_benefits.length > 0 ? (
                                    values.other_benefits.map((other_benefit, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`other_benefits.${index}`}
                                            // readOnly={data.source === 'dobin' ? false : true} 
                                        />
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button> : null
                                        }
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button> : null
                                        }
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}
                                        // disabled={ data.source === 'dobin' ? false : true }
                                    >
                                        Add Benefits
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Merchant Phone Number:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="merchant_phone_number"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="merchant_phone_number" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Merchant Logo URL:</label>
                            <Field 
                                className="col-md-8"
                                readOnly type="text" name="merchant_logo_url" />
                            <div>
                                (OR)
                            </div>
                            <Field
                                // readOnly={data.source === 'dobin' ? false : true}
                                className="col-md-8"
                                name='merchant_logo_url'>
                                {({ form, field }) => {
                                    const { setFieldValue } = form
                                    return (
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            className="col-md-8"
                                            onChange={(e) => handleIcon(e, setFieldValue, 'merchant_logo_url')} />
                                    )
                                }}
                            </Field>
                            <ErrorMessage 
                                className="text-danger" 
                                name="merchant_logo_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Website URL:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="website_url"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="website_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Redeem URL:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="redeem_url"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="redeem_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Coupon Image URL:</label>
                            <Field 
                                className="col-md-8"
                                readOnly type="text" name="coupon_image_url" />
                            <div>
                                (OR)
                            </div>
                            <Field
                                // readOnly={data.source === 'dobin' ? false : true}
                                className="col-md-8"
                                name='coupon_image_url'>
                                {({ form, field }) => {
                                    const { setFieldValue } = form
                                    return (
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            className="col-md-8"
                                            onChange={(e) => handleIcon(e, setFieldValue, 'coupon_image_url')} />
                                    )
                                }}
                            </Field>
                            <ErrorMessage 
                                className="text-danger" 
                                name="coupon_image_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Expiry Date: (SGT = UTC - 1)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="expiry_date"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Validity Start Date: (SGT = UTC)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="validity_start_date"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Currency:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="currency"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Applicable Users:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="applicable_users"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Eligible Banks:</label>
                            <FieldArray
                                className="col-md-8"
                                name="eligible_banks"
                                render={arrayHelpers => (
                                <div>
                                    {values.eligible_banks && values.eligible_banks.length > 0 ? (
                                    values.eligible_banks.map((eligible_bank, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`eligible_banks.${index}`}
                                            // readOnly={data.source === 'dobin' ? false : true} 
                                        />
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button> : null
                                        }
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button> : null
                                        }
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}
                                        // disabled={ data.source === 'dobin' ? false : true }
                                    >
                                        Add Eligible Banks
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Eligible Cards:</label>
                            <FieldArray
                                className="col-md-8"
                                name="eligible_cards"
                                render={arrayHelpers => (
                                <div>
                                    {values.eligible_cards && values.eligible_cards.length > 0 ? (
                                    values.eligible_cards.map((eligible_card, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`eligible_cards.${index}`}
                                            // readOnly={data.source === 'dobin' ? false : true} 
                                        />
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button> : null
                                        }
                                        {
                                            // data.source === 'dobin' ?
                                            data.source ?
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button> : null
                                        }
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}
                                        // disabled={ data.source === 'dobin' ? false : true }
                                    >
                                        Add Eligible Cards
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="amount"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Dobin Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="dobin_amount"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="dobin_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="percentage"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Dobin Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="dobin_percentage"
                                readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="dobin_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Additional Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="additional_amount"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="additional_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Additional Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="additional_percentage"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="additional_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Min Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="min_amount"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Min Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="min_percentage"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Max Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_amount"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Max Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_percentage"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Amount Multiplier:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="amount_multiplier"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="amount_multiplier" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Percentage Multiplier:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="percentage_multiplier"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="percentage_multiplier" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Min Spend:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="min_spend"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_spend" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Min Spend Text:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="min_spend_text"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Max Spend:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_spend"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_spend" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Multi Buy:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="multi_buy"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="multi_buy" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Multi Get:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="multi_get"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="multi_get" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Max Redeem Count:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_redeem_count"
                                // readOnly={data.source === 'dobin' ? false : true} 
                            />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_redeem_count" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-3">Featured Position:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="featured_position" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="featured_position" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Flagged:</label>
                            <Switch
                                className="col-md-8"
                                checked={values.flagged}
                                onChange={() => setFieldValue("flagged", !values.flagged)} />
                        </div>
                        {
                            (Object.keys(commission).length > 0 && 
                                commission.aggregator === 'optimise_media') ?
                            <div className="row mt-40">
                                <label className="col-md-3">Commission:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">ID:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission_id" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Aggregator:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.aggregator" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Campaign ID:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.campaign_id" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Payout:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.payout" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Type:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.type" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Type ID:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.type_id" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Value:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.value" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Percentage:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.percentage" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Lower Limit:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.lower_limit" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Upper Limit:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.upper_limit" />
                                </div>
                            </div> : null
                        }
                        {
                            (Object.keys(commission).length > 0 && 
                                commission.aggregator === 'shopee') ?
                            <div className="row mt-40">
                                <label className="col-md-3">Commission:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Aggregator:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.aggregator" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Commission Amount:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.amount" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Commission Rate:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.rate" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Seller Commission Rate:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.seller_rate" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-3">Shopee Commission Rate:</label>
                                    <Field 
                                        className="col-md-8" 
                                        readOnly type="text" name="commission.shopee_rate" />
                                </div>
                            </div> : null
                        }
                        {/* <div className="merchant-offer-container">
                            <button 
                                className="merchant-offer-button"
                                type="submit" disabled={isSubmitting}>
                                    Import
                            </button>
                        </div> */}
                    </Form>
                )}
                </Formik>
        </div>
    );
};

export default MerchantOfferAction