import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Form as BootstrapForm } from 'react-bootstrap';
import Select from 'react-select';

const FileUploadForm = ({ onFileChange, handleSubmit }) => {

  const operations = [
    { value: 'add', label: 'Add' },
    { value: 'update', label: 'Update' }
  ];

  return (
    <Formik
      initialValues={{ file: null }}
      validate={values => {
        const errors = {};
        if (!values.operation) {
          errors.operation = 'Required';
        }
        return errors;
      }}
      onSubmit={values => {
        handleSubmit(values.operation);
      }}
    >
      {({ setFieldValue }) => (
        <Form id="merchant-offer-upload">
          <BootstrapForm.Group controlId="formFile">
            <BootstrapForm.Label>Instructions:</BootstrapForm.Label>
            <ul>
                <li>Bulk additions/updates are allowed on merchant offer data</li>
                <li>Only CSV files are allowed</li>
                <li>Please ensure upload data schema matches to download data schema</li>
                <li>Bulk operations dont support image additions/updates</li>
                <li>Data fields which are list of strings needs to have elements separated by new line character and not comma</li>
            </ul>
            <Field name="file">
              {({ field }) => (
                <BootstrapForm.Control
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type === 'text/csv') {
                      setFieldValue('file', file);
                      onFileChange(e);
                    } else {
                      alert('Please upload a CSV file');
                    }
                  }}
                  accept=".csv"
                />
              )}
            </Field>
          </BootstrapForm.Group>
          <div className="row mt-40">
            <label className="col-md-2">Operation:*</label>
            <Select
                className="col-md-8"
                name="operation"
                options={operations}
                onChange={(selected) => setFieldValue("operation", selected.value)} />
            <ErrorMessage 
                className="text-danger" 
                name="operation" component="div" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FileUploadForm;
