import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import Switch from "react-switch";
import {v4 as uuidv4} from 'uuid';
import { isBase64 } from 'is-base64';
import _ from "lodash";

import callApi from "../callApi";
import { data } from "./data.js";

const AddMerchantOffers = () => {
    const navigate = useNavigate();
    data.source_id = uuidv4();
    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);

    const aggregators = [
        { value: 'optimise_media', label: 'optimise_media' },
        { value: 'impact', label: 'impact' },
        { value: 'involve_asia', label: 'involve_asia' },
        { value: 'rakuten', label: 'rakuten' },
        { value: 'shopee', label: 'shopee' },
    ];

    const voucher_types = [
        { value: 'multi_buy', label: 'Multi Buy' },
        { value: 'discount', label: 'Discount' },
        { value: 'cashback', label: 'Cashback' },
        { value: 'free_delivery', label: 'Free Delivery' },
        { value: 'free_shipping', label: 'Free Shipping' },
        { value: 'gift', label: 'Gift' }
    ];

    const merchant_offer_number_fields = [
        "amount", "dobin_amount", "percentage", "dobin_percentage", 
        "additional_amount", "additional_percentage",
        "min_amount", "min_percentage", "max_amount", "max_percentage",
        "amount_multiplier", "percentage_multiplier", "min_spend", "max_spend",
        "multi_buy", "multi_get", "max_redeem_count", "featured_position",
    ];

    const getCategories = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/categories`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.categories) {
                    let category_ids = Object.keys(json.data.categories);
                    let categories = [];
                    for (let category_id of category_ids) {
                        categories.push({
                            value: category_id,
                            label: category_id
                        });
                    }
                    setCategories(categories);
                }
            })
            .catch((err) => err);
    };

    const getCountries = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/core/country_codes`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.country_codes) {
                    let countries = [];
                    for (let country_code of json.data.country_codes) {
                        countries.push({
                            value: country_code,
                            label: country_code
                        });
                    }
                    setCountries(countries);
                }
            })
            .catch((err) => err);
    };

    const dataTransformations = (merchant_offer) => {
        let result = {};
        if ('flagged' in data && data['flagged'] === false) {
            delete data['flagged']
        }
        for (let key in merchant_offer) {
            if (merchant_offer_number_fields.includes(key) && merchant_offer[key] !== "") {
                result[key] = parseFloat(merchant_offer[key]);
            }
            else if(typeof(merchant_offer[key]) == "string" && merchant_offer[key] !== "") {
                result[key] = merchant_offer[key];
            }
            else if(_.isArray(merchant_offer[key]) && merchant_offer[key].length > 0) {
                let items = [];
                for (let value of merchant_offer[key]) {
                    if (value !== "") {
                        items.push(value);
                    }
                }
                if(items.length > 0) {
                    result[key] = items;
                }
            }
            // else if(typeof(merchant_offer[key]) == "object") {
            //     let object_result = dataTransformations(merchant_offer[key]);
            //     if (!_.isEmpty(object_result)) {
            //         result[key] = object_result;
            //     }
            // }
            else if(typeof(merchant_offer[key]) == "boolean") {
                result[key] = merchant_offer[key];
            }
        }
        return result;
    };

    const saveMerchantOffer = (merchant_offer, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/merchants/merchant_offers`;
        callApi(url, 'POST', {'merchant_offers': [merchant_offer]})
            .then((json) => {
                setSubmitting(false);
                navigate('/merchant-offers');
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getCategories();
        }
    }, [categories]);

    useEffect(() => {
        if (countries.length === 0) {
            getCountries();
        }
    }, [countries]);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    
    const handleIcon = async (e, setFieldValue, fieldName) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setFieldValue(fieldName, base64);
    };

    return (
        <div className="merchant-offer-page">
            <h1 className="title">
                Merchant Offers - Add Form
            </h1>
            <Formik
                initialValues={data}
                validate={values => {
                    const phoneNumberRegex = /(\+65)?(8|9)\d{7}/;
                    const isNumberRegex = /^-?\d*\.?\d*$/;
                    const errors = {};
                    if (!values.country_code) {
                        errors.country_code = 'Required';
                    }
                    if (!values.voucher_type) {
                        errors.voucher_type = 'Required';
                    }
                    if (values.categories.length > 0 && !values.categories[0]) {
                        errors.categories = 'Required';
                    }
                    if (!values.title) {
                        errors.title = 'Required';
                    }
                    if (!values.merchant_name) {
                        errors.merchant_name = 'Required'
                    };
                    if (!values.coupon_url) {
                        errors.coupon_url = 'Required'
                    }
                    if (values.has_code && !values.promo_code) {
                        errors.promo_code = 'Required'
                    }
                    if (values.merchant_phone_number && !(phoneNumberRegex.test(values.merchant_phone_number))) {
                        errors.merchant_phone_number = 'Enter valid Singapore phone number'
                    }
                    if (values.coupon_image_url && !(isBase64(values.coupon_image_url, { allowMime: true }))) {
                        errors.coupon_image_url = 'Invalid URL'
                    }
                    if (values.merchant_logo_url && !(isBase64(values.merchant_logo_url, { allowMime: true }))) {
                        errors.merchant_logo_url = 'Invalid URL'
                    }
                    for (let value of ['website_url', 'redeem_url', 'coupon_url']) {
                        if (values[value] && !values[value].startsWith('https://')) {
                            errors[value] = 'Invalid URL'
                        }
                    }
                    for (let value of merchant_offer_number_fields) {
                        if (values[value] && !(isNumberRegex.test(values[value]))) {
                            errors[value] = "Invalid Number";
                        }
                    }
                    if (values.dobin_amount && values.dobin_percentage) {
                        errors.dobin_amount = "We can't have both dobin amount and dobin percentage"
                        errors.dobin_percentage = errors.dobin_amount
                    }
                    if (values.dobin_amount) {
                        if (values.amount === '' || values.amount === undefined || values.amount === null) {
                            errors.amount = 'There is a Dobin Amount. Thus, Amount cannot be empty! Enter 0 or other numeric value'
                        }
                        if (values.percentage) {
                            errors.percentage = 'There is a Dobin Amount. Thus, Percentage should be empty!'
                        }
                    }
                    if (values.dobin_percentage) {
                        if (values.percentage === '' || values.percentage === undefined || values.percentage === null) {
                            errors.percentage = 'There is a Dobin Percentage. Thus, Percentage cannot be empty! Enter 0 or other numeric value'
                        }
                        if (values.amount) {
                            errors.amount = 'There is a Dobin Percentage. Thus, Amount should be empty!'
                        }
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    let merchant_offer = dataTransformations(values);
                    saveMerchantOffer(merchant_offer, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="row mt-40">
                            {/* Remove this field once all integrations are completed */}
                            <label className="col-md-2">Aggregator:</label>
                            <Select
                                className="col-md-8"
                                name="aggregator"
                                options={aggregators}
                                onChange={(selected) => setFieldValue("aggregator", selected.value)} />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Source:*</label>
                            <Field 
                                className="col-md-8" 
                                readOnly type="text" name="source" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Source ID:*</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="source_id" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Country Code:*</label>
                            <Select
                                className="col-md-8"
                                name="country_code"
                                options={countries}
                                defaultValue={
                                    { 
                                        value: data.country_code, 
                                        label: data.country_code
                                    }
                                }
                                onChange={(selected) => setFieldValue("country_code", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="country_code" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Voucher Type:*</label>
                            <Select
                                className="col-md-8"
                                name="voucher_type"
                                options={voucher_types}
                                onChange={(selected) => setFieldValue("voucher_type", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="voucher_type" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Categories:*</label>
                            <Select
                                isMulti
                                className="col-md-8"
                                name="categories"
                                options={categories}
                                onChange={(selected) => {
                                    let categories = [];
                                    for (let item of selected) {
                                        categories.push(item.value);
                                    }
                                    setFieldValue("categories", categories);
                                }} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="categories" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Title:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="title" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="title" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Merchant Name:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="merchant_name" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="merchant_name" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Coupon URL:*</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="coupon_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="coupon_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Has Code:*</label>
                            <Switch
                                className="col-md-8"
                                checked={values.has_code}
                                onChange={() => setFieldValue("has_code", !values.has_code)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="has_code" component="div" />
                        </div>
                        {
                            values.has_code ? <div className="row mt-40">
                                <label className="col-md-2">Promo Code:*</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="promo_code" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="promo_code" component="div" />
                            </div> : null
                        }
                        <div className="row mt-40">
                            <label className="col-md-2">Description:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="description" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Restrictions:</label>
                            <FieldArray
                                className="col-md-8"
                                name="other_restrictions"
                                render={arrayHelpers => (
                                <div>
                                    {values.other_restrictions && values.other_restrictions.length > 0 ? (
                                    values.other_restrictions.map((other_restriction, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`other_restrictions.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        {/* show this when user has removed all friends from the list */}
                                        Add Restrictions
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Benefits:</label>
                            <FieldArray
                                className="col-md-8"
                                name="other_benefits"
                                render={arrayHelpers => (
                                <div>
                                    {values.other_benefits && values.other_benefits.length > 0 ? (
                                    values.other_benefits.map((other_benefit, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`other_benefits.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        {/* show this when user has removed all friends from the list */}
                                        Add Benefits
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Merchant Phone Number:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="merchant_phone_number" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="merchant_phone_number" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Merchant Logo URL:</label>
                            <Field
                                className="col-md-8"
                                name='merchant_logo_url'>
                                {({ form, field }) => {
                                    const { setFieldValue } = form
                                    return (
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            className="col-md-8"
                                            onChange={(e) => handleIcon(e, setFieldValue, 'merchant_logo_url')} />
                                    )
                                }}
                            </Field>
                            <ErrorMessage 
                                className="text-danger" 
                                name="merchant_logo_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Website URL:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="website_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="website_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Redeem URL:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="redeem_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="redeem_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Coupon Image URL:</label>
                            <Field
                                className="col-md-8"
                                name='coupon_image_url'>
                                {({ form, field }) => {
                                    const { setFieldValue } = form
                                    return (
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            className="col-md-8"
                                            onChange={(e) => handleIcon(e, setFieldValue, 'coupon_image_url')} />
                                    )
                                }}
                            </Field>
                            <ErrorMessage 
                                className="text-danger" 
                                name="coupon_image_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Expiry Date: (SGT = UTC - 1)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="expiry_date" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Validity Start Date: (SGT = UTC)</label>
                            <Field 
                                className="col-md-8"
                                type="date" name="validity_start_date" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Currency:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="currency" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Applicable Users:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="applicable_users" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Eligible Banks:</label>
                            <FieldArray
                                className="col-md-8"
                                name="eligible_banks"
                                render={arrayHelpers => (
                                <div>
                                    {values.eligible_banks && values.eligible_banks.length > 0 ? (
                                    values.eligible_banks.map((eligible_bank, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`eligible_banks.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        {/* show this when user has removed all friends from the list */}
                                        Add Eligible Banks
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Eligible Cards:</label>
                            <FieldArray
                                className="col-md-8"
                                name="eligible_cards"
                                render={arrayHelpers => (
                                <div>
                                    {values.eligible_cards && values.eligible_cards.length > 0 ? (
                                    values.eligible_cards.map((eligible_card, index) => (
                                        <div key={index}>
                                        <Field className="col-md-6" name={`eligible_cards.${index}`} />
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                            -
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                        >
                                            +
                                        </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        {/* show this when user has removed all friends from the list */}
                                        Add Eligible Cards
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Dobin Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="dobin_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="dobin_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="percentage" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Dobin Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="dobin_percentage" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="dobin_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Additional Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="additional_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="additional_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Additional Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="additional_percentage" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="additional_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Min Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="min_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Min Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="min_percentage" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Max Amount:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Max Percentage:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_percentage" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_percentage" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Amount Multiplier:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="amount_multiplier" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="amount_multiplier" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Percentage Multiplier:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="percentage_multiplier" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="percentage_multiplier" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Min Spend:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="min_spend" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_spend" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Min Spend Text:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="min_spend_text" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Max Spend:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_spend" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_spend" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Multi Buy:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="multi_buy" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="multi_buy" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Multi Get:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="multi_get" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="multi_get" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Max Redeem Count:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="max_redeem_count" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_redeem_count" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Featured Position:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="featured_position" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="featured_position" component="div" />
                        </div>
                        <div className="merchant-offer-container">
                            <button 
                                className="merchant-offer-button"
                                type="submit" disabled={isSubmitting}>
                                    Save
                            </button>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Flagged:</label>
                            <Switch
                                className="col-md-8"
                                checked={values.flagged}
                                onChange={() => setFieldValue("flagged", !values.flagged)} />
                        </div>
                    </Form>
                )}
                </Formik>
        </div>
    );
};


export default AddMerchantOffers;